<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">结算确认单</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="结算单生成日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">结算单生成日期:</span>
              <el-date-picker
                v-model="date"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div title="结算单编号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">结算单编号:</span>
              <el-input v-model="billNo" type="text" clearable size="small" placeholder="请输入结算单编号" />
            </div>

            <el-button class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="60"
                :index="indexMethod"
              />

              <el-table-column
                label="结算单生成日期"
                align="center"
                prop="createTime"
                show-overflow-tooltip
                min-width="140"
              />

              <el-table-column
                label="结算单编号"
                align="center"
                prop="billNo"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="结算人数"
                align="center"
                prop="settlementUserCount"
                show-overflow-tooltip
                min-width="120"
              />
              <el-table-column
                label="付款金额"
                align="center"
                prop="payerAmount"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="平台技术服务费（元）"
                align="center"
                prop="techniqueMoney"
                show-overflow-tooltip
                min-width="150"
              >
               <template slot-scope="scope">
                {{scope.row.techniqueMoney ? scope.row.techniqueMoney + '元':'--'}}
              </template>
              </el-table-column>
              <el-table-column
                label="线上课程服务费（元）"
                align="center"
                prop="courseMoney"
                show-overflow-tooltip
                min-width="150"
              >
               <template slot-scope="scope">
                {{scope.row.courseMoney ? scope.row.courseMoney + '元':'--'}}
              </template>
              </el-table-column>
              <el-table-column
                label="平台服务费"
                align="center"
                prop="platformMoney"
                show-overflow-tooltip
                min-width="150"
              >
              <template slot-scope="scope">
                {{scope.row.platformMoney ? scope.row.platformMoney + '元':'--'}}
              </template>
              </el-table-column>

              <el-table-column label="操作" align="center" width="140px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="lookPdf(scope.row.billId)"
                  >预览</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
      title="预览"
      :visible.sync="DialogVisible"
      width="70%"
      top="2%"
      center
      :before-close="closeDownloadTrainDocument"
    >
      <div id="pdf-content" style="height: 700px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "SettlementmanagementList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      DialogVisible: false,
      date: "",
      billNo: ""
    };
  },
  computed: {},
  created() {},
  beforeRouteLeave: resetKeepAlive,
  methods: {
    closeDownloadTrainDocument(){
      this.DialogVisible = false;
    },
    getData(pageNum = 1) {
      console.log(this.date);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.billNo) {
        params.billNo = this.billNo;
      }
      if (this.date && this.date.length) {
        params.createTimeStart = this.date[0];
        params.createTimeEnd = this.date[1];
      }
      this.doFetch({
        url: "/biz/settlement/bill/pageOrder",
        params,
        pageNum
      });
    },
    // 查看pdf
    lookPdf(billId) {
      this.$post("/biz/settlement/bill/billDetailsAndProjectDetail", { billId }).then(res => {
        ;
        this.DialogVisible = true;
        this.$nextTick(() => {
          pdf.embed(res.data, "#pdf-content");
        });
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight -
        (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 - 100;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    }
  },

  watch: {}
};
</script>

<style lang="less" scoped>
.seachStyle {
  .searchList {
    .listLabel {
      min-width: 88px !important;
    }
  }
}
</style>
